import { SentryDSN } from '$lib/sentry';
import * as Sentry from '@sentry/svelte';
import { BrowserTracing } from '@sentry/browser';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
	dsn: SentryDSN,
	tracesSampleRate: 1.0,
	integrations: [new BrowserTracing()],
	ignoreErrors: [
		/Not found\:.*wp-(includes|content).*/,
		/Not found\:.*\.aws\/.*/,
		/Not found\:.*\/plugins\/.*/,
		'Not found: /ads.txt',
		'Not found: /favicon.ico',
		'Not found: /robots.txt',
		'Not found: /apple-touch-icon.png',
		'Not found: /robots.txt'
	]
});

Sentry.setTag('svelteKit', 'client');

export const handleError: HandleClientError = ({ error, event }) => {
	Sentry.captureException(error, { contexts: { sveltekit: { event } } });

	return {
		message: (error as App.Error).message
	};
};
