import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/": [5],
		"/contact": [6],
		"/portal": [7,[2]],
		"/portal/admin": [8,[2]],
		"/portal/clients": [9,[2]],
		"/portal/clients/new": [16,[2]],
		"/portal/clients/[clientId]": [10,[2,3]],
		"/portal/clients/[clientId]/edit": [11,[2,3]],
		"/portal/clients/[clientId]/projects": [12,[2,3]],
		"/portal/clients/[clientId]/projects/new": [15,[2,3]],
		"/portal/clients/[clientId]/projects/[projectId]": [13,[2,3,4]],
		"/portal/clients/[clientId]/projects/[projectId]/edit": [14,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';